.card {
  width: 800px;

  .userProfileContainer {
    margin-top: 30px;

    .profileRedirect {
      background-color: #fafafa;
      padding: 10px;
      margin-right: 50px;
      border-radius: 3px;

      .userProfile {
        font-size: 13px;
        color: #000;
        opacity: 0.47;
      }

      .arrangerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        cursor: pointer;

        .profileName {
          color: #00aeef;
          font-weight: 400;
        }

        .arranger {
          color: #000;
          font-size: 12px;
          margin-bottom: 10px;
          background-color: #e5f7fd;
          border-radius: 20px;
          padding: 4px;
          margin-right: 50px;
        }
      }
    }
  }
  .cardContainer {
    margin-top: 25px;

    .editPassenger {
      background-color: #00aeef;
      color: #fff;
      width: 88%;
      text-transform: capitalize;
      font-size: 18px;
    }
    .removePassenger {
      text-transform: capitalize;
      color: #eb7774;
      font-size: 18px;
      border: 1px solid #eb7774;
      width: 88%;
    }

    .userProfile {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .profile {
      margin-bottom: 20px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .profileName {
        font-size: 18px;
      }
      .editProfile {
        color: #00aeef;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .defaultWrapper {
      background-color: #e5f7fd;
      padding: 2px;
      width: 40px;
      border-radius: 10px;
      margin-bottom: 10px;

      .defaultSize {
        font-size: 11px;
        font-weight: 400;
      }
    }

    .cardTextWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 18px;
    }
    .cardTextWrapperPhone,
    .cardTextWrapperEmail {
      display: flex;
      flex-direction: row;
      padding-bottom: 18px;
      margin-top: 30px;
      .addNewBtn {
        cursor: pointer;
        background-color: #04adef;
        color: #fff;
        min-width: 35px;
        padding: 5px 0;
      }
    }
    .cardTextWrapperNewPhone,
    .cardTextWrapperNewEmail {
      display: flex;
      flex-direction: column;
      .errorMessage {
        color: red;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 13px;
      }
    }
    .leftCardText {
      width: 30%;
      color: #000;
      font-size: 16px;
      .leftTitle {
        opacity: 0.5;
      }
    }
    .leftCardTextPhone,
    .leftCardTextEmail {
      margin-top: 15px;
    }
    .rightCardText {
      width: 70%;
      font-size: 16px;
    }
    .btnWrapper {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 20px;
      width: 100%;

      .editProfile {
        background: #00aeef;
        color: #fff;
        width: 160px;
        text-transform: capitalize;
        font-size: 16px;
      }

      .deleteAccount {
        border: 2px solid #eb7774;
        color: #eb7774;
        width: 100%;
        // width: 160px;
        // margin-left: 20px;
        text-transform: capitalize;
        font-size: 16px;
        margin-right: 45px;
      }
    }
  }
}

@media (max-width: 780px) {
  .card .cardTextWrapperPhone,
  .card .cardTextWrapperEmail {
    flex-direction: column !important;
    .rightCardText {
      width: 100% !important;
    }
  }
}

#deleteAccountDialog {
  text-align: center;

  .dialog {
    margin-top: 25px;
    width: 300px;
    .account {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .textWrapper {
      width: 80%;
      margin: 0 auto;

      .text {
        font-size: 14px;
        color: #000;
        opacity: 0.5;
      }
    }

    .terms {
      width: 80%;
      margin: 0 auto;
      margin-top: 10px;
    }

    .dialogBtnWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .delete {
        background: #eb7774;
        color: #fff;
        width: 100%;
        margin-top: 15px;
        text-transform: capitalize;
        font-size: 16px;
      }
      .toProfile {
        color: #00aeef;
        border-color: #00aeef;
        width: 100%;
        margin-top: 12px;
        margin-bottom: 20px;
        text-transform: capitalize;
        font-size: 16px;
      }
    }
  }
}

.delete-phone,
.delete-email {
  .MuiPaper-root {
    padding: 10px;
    .delete-question {
      font-size: 12px;
    }
    .delete-no {
      float: left;
      background-color: red;
      color: #fff;
      font-size: 12px;
    }
    .delete-yes {
      float: right;
      background-color: rgb(0, 174, 239);
      color: #fff;
      font-size: 12px;
    }
  }
}

@media (max-width: 780px) {
  .card {
    width: 400px;
    .leftCardText {
      width: 40% !important;
    }
    .rightCardText {
      width: 60% !important;
      margin-left: 0 !important;
    }

    .cardContainer {
      .btnWrapper {
        .editProfile {
          width: 140px;
        }
        .deleteAccount {
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .card {
    width: 350px;

    .userProfileContainer {
      margin-left: 0px;

      .profileRedirect {
        margin-right: 0px;
      }

      .arrangerContainer {
        .arranger {
          margin-right: 0px;
        }
      }
    }

    .cardContainer {
      .editPassenger {
        width: 100%;
      }
      .removePassenger {
        width: 100%;
      }
      .rightCardText {
        margin-left: -50px;
      }
      .btnWrapper {
        flex-direction: column;
        .editProfile {
          width: 100%;
        }

        .deleteAccount {
          width: 100%;
          margin-left: 0px;
          margin-top: 12px;
        }
      }
    }
  }
}
