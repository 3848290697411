.offlineIntMsgModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiBackdrop-root {
    top: 0 !important;
  }
  .confirmationBox {
    display: flex !important;
    justify-content: flex-start;
    // position: relative;
    padding: 20px 10px 15px 10px;
    text-align: center;
    border-radius: 8px;
    // height: 40%;
    max-width: 500px;
    // max-height: 280px;
    .close-wrap {
      position: absolute;
      // top: -15px !important;
      // right: -5px !important;
      top: 10px;
      right: 10px;
      cursor: pointer;
      img {
        width: 13px;
        height: 13px;
      }
    }
    .btnChoose {
      height: 100%;
      flex-direction: column;
      gap: 10px;
      font-size: 16px;
      color: #000;
      padding: 0 !important;
      margin: 0 !important;
      background-color: transparent !important;
      svg {
        width: 60px;
        height: 60px;
        @media (max-width: 1200px) {
          width: 50px;
          height: 50px;
        }
      }
      @media (max-width: 780px) {
        svg {
          width: 40px;
          height: 40px;
        }
        font-size: 14px;
      }
    }
    .btn-wrap {
      width: 50%;
      // height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 130px;
      @media (max-width: 780px) {
        min-height: 100px;
      }
    }
    @media (max-width: 375px) {
      padding-block: 25px;
    }
    .bgWhiteBanner {
      border-radius: 3px;
      overflow: hidden;
      @media (min-width: 781px) {
        margin-top: 10px;
      }
    }
  }
  @media (min-width: 1201px) {
    .confirmationBox {
      width: 60% !important;
    }
  }

  @media (max-width: 1200px) {
    .confirmationBox {
      width: 50% !important;
    }
  }

  @media (max-width: 780px) {
    .confirmationBox {
      width: 90% !important;
      // max-height: 250px;
    }
  }
}
