.card {
  .addressBookContainer {
    margin-top: 25px;
    margin-left: 50px;
  }
}
.passengerListContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .passengersList {
    margin-left: 50px;
    font-size: 22px;
    padding-top: 30px;
  }
  .count {
    text-align: right;
    margin-right: 50px;
    margin-top: 30px;
  }
  .addPpl {
    margin-left: 50px;
    margin-top: 5px;
    color: #717171;
    font-size: 14px;
  }
  .addPplBtn {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
  }
  .passengerContainer {
    border: 1px solid #838383;
    border-radius: 2px;
    margin-left: 50px;
    padding: 10px;
    margin-top: 15px;
    margin-right: 50px;

    .passType {
      color: #000;
      font-size: 12px;
      margin-bottom: 10px;
      background-color: #e5f7fd;
      border-radius: 20px;
      padding: 4px;
    }

    .passengerAddressbook {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      cursor: pointer;

      .fullName {
        color: #000;
        opacity: 0.47;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .arranger {
        color: #000;
        font-size: 12px;
        margin-bottom: 10px;
        background-color: #e5f7fd;
        border-radius: 20px;
        padding: 4px;
      }
      .fullNameText {
        color: #000;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 400px) {
  .passengerListContainer {
    .passengersList {
      margin-left: 0px;
    }
    .addPpl {
      margin-left: 0px;
    }
    .count {
      margin-right: 0px;
    }
    .passengerContainer {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .card {
    .addressBookContainer {
      margin-left: 0px;
    }
  }
  .card {
    .cardContainer {
      margin-left: 0px;
    }
  }
}
